import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { cFetch } from "../api/apiUtils";
import { to_readable_date } from "../utils";
import ReactGA from "react-ga";

function NewsSection() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    //   MockUp JSON https://cdn.nowpurchase.com/backendMockup.json
    // Backend URL ${process.env.API_URL}/articles/?format=json&page=${page}
    cFetch(`${process.env.API_URL}/articles/?format=json`).then(data => {
      setArticles(data.results);
    });
  }, []);

  function CheckAnalytics(category_name, action_value) {
    ReactGA.event({
      category: category_name,
      action: action_value
    });
  }

  return (
    <React.Fragment>
      <section className="section" id="news">
        <div className="container">
          <Row>
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  Positive News
                </h3>
                <div className="title-border-simple position-relative"></div>
              </div>
              <p className="text-muted f-14">
                We have curated positive news from across the globe through
                sentiment analysis
              </p>
            </Col>
          </Row>

          <div className="row">
            {articles.map((news, index) => {
              if (index < 6) {
                return (
                  <Col lg="4" key={index}>
                    <div className="blog position-relative">
                      <div className="blog-img position-relative mt-4">
                        <div className="blog-date" style={{ zIndex: 1 }}>
                          <p className="mb-0 font-weight-light text-white f-15">
                            {to_readable_date(news.published_at, "DD-MM-YYYY")}
                          </p>
                        </div>
                        <div
                          style={{ position: "relative", paddingTop: "67%" }}
                        >
                          <div
                            className="flexCenter"
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0
                            }}
                          >
                            <img
                              src={
                                news.image_url
                                  ? news.image_url
                                  : "public/images/blog/img-2.jpg"
                              }
                              alt=""
                              className="img-fluid mx-auto d-block rounded"
                              onClick={CheckAnalytics(
                                "NEWS_IMAGE_CLICK",
                                `Clicked on ${news.title} news image`
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="position-relative">
                        <div className="blog-content text-center bg-white p-4">
                          {/* <p className="text-uppercase f-13 mb-2 text-muted">
                              {news.title}
                            </p> */}
                          <h5
                            className="font-weight-normal f-18"
                            onClick={CheckAnalytics(
                              "NEWS_TITLE_CLICK",
                              `Clicked on ${news.title} news title`
                            )}
                          >
                            <Link to="#" className="text-dark">
                              {news.title}
                            </Link>
                          </h5>
                          <p className="text-muted f-14">
                            {(news.content || news.description)
                              .replace(/\[(.*?)\]/g, "")
                              .slice(0, 257)}
                            ...
                          </p>
                          <div
                            className="read-more"
                            onClick={CheckAnalytics(
                              "NEWS_READ_MORE_CLICK",
                              `Clicked on ${news.title} news read more`
                            )}
                          >
                            <a
                              href={news.news_url}
                              className=" text-primary f-15"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read more<i className="mdi mdi-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              }
            })}
          </div>
          <p
            className="mb-0 text-uppercase f-13"
            style={{ textAlign: "right" }}
          >
            <Link
              to="/feed"
              onClick={CheckAnalytics(
                "NEWS_VIEW_ALL_CLICK",
                "Clicked on view all news button"
              )}
            >
              view all <span className="right-arrow ml-1">&#x21FE;</span>
            </Link>
          </p>
        </div>
      </section>
    </React.Fragment>
  );
}
export default NewsSection;
