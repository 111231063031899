import React, { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import "../css/feed.css";
import "../css/level.scss";
import { cFetch } from "../api/apiUtils";
import SpinnerWrapper from "./spinner";
import { to_readable_date } from "../utils";
import ReactGA from "react-ga";
import Level from "./level";
import Minigraph from "./minigraph";
import ImagePlaceHolder from "../common/imagePlaceHolder";
import ScrollspyNav from "./scrollSpy";
const url = require("url");

const validateCTS = (data = []) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dataTypes = [
    "dailyconfirmed",
    "dailydeceased",
    "dailyrecovered",
    "totalconfirmed",
    "totaldeceased",
    "totalrecovered"
  ];
  return data
    .filter(d => dataTypes.every(dt => d[dt]) && d.date)
    .filter(d => dataTypes.every(dt => Number(d[dt]) >= 0))
    .filter(d => {
      const year = today.getFullYear();
      return new Date(d.date + year) < today;
    });
};

const preprocessTimeseries = timeseries => {
  return validateCTS(timeseries).map(stat => ({
    date: new Date(stat.date + " 2020"),
    totalconfirmed: +stat.totalconfirmed,
    totalrecovered: +stat.totalrecovered,
    totaldeceased: +stat.totaldeceased,
    dailyconfirmed: +stat.dailyconfirmed,
    dailyrecovered: +stat.dailyrecovered,
    dailydeceased: +stat.dailydeceased
  }));
};
/**
 * statTable function pulls data from covid19india like confirmed, active, deceased and recovered
 */
function StatTable() {
  const [statData, setStatData] = useState({});
  const [timeSeries, setTimeSeries] = useState([]);
  useEffect(() => {
    cFetch("https://api.covid19india.org/data.json").then((data, status) => {
      if (data.statewise && data.statewise.length > 0) {
        setStatData(data.statewise[0]);
        // console.log(preprocessTimeseries(data.cases_time_series));
        setTimeSeries(preprocessTimeseries(data.cases_time_series));
      }
    });
  }, []);

  if (statData.length === 0 || timeSeries.length === 0) return null;

  return (
    <div className="text-center">
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 30 }}>
          <Level data={statData} />
        </div>
        <Minigraph timeseries={timeSeries} />
      </div>
      <div
        className="sourceMsg"
        style={{ margin: "10px 0", textAlign: "center" }}
      >
        *crowd sourced from covid19india.org
      </div>
    </div>
  );
}

/**
 *
 * @param {Object} props component props
 * @param {bool} props.modal add new article form modal trigger visibility
 * @param {bool} props.toggle toggle form visibility
 */
function ArticleForm({ modal, toggle }) {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Post news</ModalHeader>
      <ModalBody>form</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Submit
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

/**
 * Feed form main component.
 */
function Feed() {
  const [pageLoading, setPageLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  //article Input form Modal
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  // for google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document.getElementById("main_navbar").classList.remove("navbar-light");
  }, []);

  useEffect(() => {
    setPageLoading(true);
    //   MockUp JSON https://cdn.nowpurchase.com/backendMockup.json
    // Backend URL ${process.env.API_URL}/articles/?format=json&page=${page}
    cFetch(`${process.env.API_URL}/articles/?format=json&page=${page}`).then(
      data => {
        setArticles(data.results);
        setTotalPages(Math.ceil(data.count / 12));
        setPageLoading(false);
      }
    );
  }, [page]);

  if (pageLoading)
    return (
      <div style={{ height: "100vh" }} className="flexCenter">
        <Spinner color="success" style={{ width: "2rem", height: "2rem" }} />
      </div>
    );

  function PagesList() {
    const buttonNeighbor = 2;
    const startIndex = page - buttonNeighbor > 0 ? page - buttonNeighbor : 1;
    const endIndex =
      totalPages - page < buttonNeighbor ? totalPages : page + buttonNeighbor;
    const [pageArray, setPageArray] = useState([]);

    useEffect(() => {
      let newPages = [];
      for (let i = startIndex; i <= endIndex; i++) {
        newPages.push(i);
      }
      setPageArray(newPages);
    }, [startIndex, endIndex]);

    return (
      <>
        {pageArray.map(index => (
          <PaginationItem key={index} active={page === index}>
            <PaginationLink onClick={() => setPage(index)}>
              {index}
            </PaginationLink>
          </PaginationItem>
        ))}
      </>
    );
  }

  return (
    <>
      <section className="document_container section">
        <div className=" mt-40"></div>
        <div className="container">
          <div className="feedBody " style={{ marginTop: 15 }}>
            <div className="flexCenter feedHeader">
              <div style={{ flex: 1 }}>
                <Row>
                  <Col className="col-lg-12">
                    <div className="title-heading mb-5">
                      <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                        Awareness Resourses for Workplaces
                      </h3>
                      <div className="title-border-simple position-relative"></div>
                    </div>
                  </Col>
                </Row>
              </div>
              <StatTable />
            </div>
            <Row>
              {articles
                .filter(news => news.content || news.description)
                .map((news, index) => {
                  if (
                    news.image_url &&
                    !news.image_url.startsWith("http") &&
                    news.news_url
                  ) {
                    let parsed_url = url.parse(news.news_url, true);
                    let base_url = `${parsed_url.protocol}://${parsed_url.host}`;
                    news.image_url = base_url + news.image_url;
                  }
                  return (
                    <Col md={4} sm={12} key={index}>
                      <Card className="articles">
                        <div className="newsTitle">{news.title}</div>
                        <div className="newsContent">
                          {(news.content || news.description).replace(
                            /\[(.*?)\]/g,
                            ""
                          )}
                          &nbsp;&nbsp;
                          <a
                            className="readMore"
                            href={news.news_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            read more
                          </a>
                        </div>
                        {news.image_url && (
                          <img alt="" className="newImg" src={news.image_url} />
                        )}
                        {!news.image_url && <ImagePlaceHolder />}
                        <div
                          className="sourceMsg flexCenter"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div>{to_readable_date(news.published_at)}</div>
                          <div>source: {news.source_name}</div>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
            {totalPages > 1 && (
              <div className="pagination">
                <Pagination className="paginationChild">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink first onClick={() => setPage(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      previous
                      onClick={() => setPage(page - 1)}
                    />
                  </PaginationItem>
                  <PagesList />
                  <PaginationItem disabled={page === totalPages}>
                    <PaginationLink next onClick={() => setPage(page + 1)} />
                  </PaginationItem>
                  <PaginationItem disabled={page === totalPages}>
                    <PaginationLink last onClick={() => setPage(totalPages)} />
                  </PaginationItem>
                </Pagination>
              </div>
            )}
          </div>
          <div
            className="text-center"
            style={{ margin: "20px 0", fontStyle: "italic" }}
          >
            Disclaimer: The news are curated algorithmically and assessed based
            on automated sentiment analysis and may not meet the expected
            criteria by the reader.
          </div>
          <ArticleForm modal={modal} toggle={toggle} />
          {/* <div className="postButton" onClick={toggle}>
        <FontAwesomeIcon size="3x" icon={faPlusCircle} color="#007bff" />
      </div> */}
        </div>
        <ScrollspyNav
          scrollTargetIds={[
            "home",
            "products",
            "resources",
            "client",
            "news",
            "contact"
          ]}
          activeNavClass="active"
          scrollDuration="800"
          headerBackground="true"
          className="ml-auto"
        ></ScrollspyNav>
      </section>
    </>
  );
}

export default Feed;
