import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import ReactGA from "react-ga";

class AwarenessVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      VideoList: [
        {
          thumbnail: "/safety/English_Thumbnail.jpg",
          title: "English Version",
          id: "XlJWLk_sA1A"
          //   content:
          //     "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni."
        },
        {
          thumbnail: "/safety/Hindi_thumbnail.jpg",
          title: "Hindi Version",
          id: "9RTOt2WFI8s"
          //   content:
          //     "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni."
        },
        {
          thumbnail: "/safety/Bengali_Thumbnail.jpg",
          title: "Bengali Version",
          id: "qqRdsCjsOYA"
          //   content:
          //     "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni."
        }
      ]
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  CheckAnalytics(category_name, action_value) {
    ReactGA.event({
      category: category_name,
      action: action_value
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-features  bg-light"
          style={{ paddingTop: 40 }}
        >
          <div className="container">
            <Row className="align-items-center" style={{ padding: "30px 0" }}>
              <Col lg="12">
                <div className="features-content">
                  {/* <div className="features-icon">
                    <i className="pe-7s-science text-primary"></i>
                  </div> */}
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Awareness videos for staff
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              {this.state.VideoList.map((video, index) => {
                return (
                  <Col lg="4" key={index}>
                    <div className="text-center p-3">
                      <div
                        className="mb-4"
                        style={{ position: "relative" }}
                        onClick={() =>
                          this.CheckAnalytics(
                            "VIDEO_PLAY_CLICK",
                            `Clicked on Awareness-Video-${video.title} to play.`
                          )
                        }
                      >
                        <div className="video-button">
                          <Link
                            to="#"
                            onClick={this.openModal}
                            className="video-play-icon"
                          >
                            <i className="mdi mdi-play play-icon-circle text-primary position-relativer"></i>
                          </Link>
                        </div>
                        <img
                          src={`${process.env.IMGIX_URL}${video.thumbnail}?auto=format&auto=compress&h=1000`}
                        />
                      </div>
                      <h4
                        className="font-weight-light"
                        onClick={() =>
                          this.CheckAnalytics(
                            "VIDEO_TITLE_CLICK",
                            `Clicked on Awareness-Video-${video.title} title.`
                          )
                        }
                      >
                        <Link to="#" className="text-dark">
                          {video.title}
                        </Link>
                      </h4>
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId={video.id}
                        onClose={() => this.setState({ isOpen: false })}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AwarenessVideo;
