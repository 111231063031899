import React from "react";
import { Row, Col, Button } from "reactstrap";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from "react-bootstrap-carousel";

const styles = { height: 400, width: "50%" };

const CliendSlider1 = [
  {
    imgsrc: "safety/Kiswok.jpg"
  },
  {
    imgsrc: "safety/sif.png"
  },
  {
    imgsrc: "safety/nipha.jpg"
  },
  {
    imgsrc: "safety/nif.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/britania.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/tata-metalics.jpg"
  }
];
const CliendSlider2 = [
  {
    imgsrc: "assets/images/client/centuryply.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/motherdiary.gif"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/IFB-2.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/adani.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/minera-steel.jpg"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/aic-steel.png"
  }
];
const CliendSlider3 = [
  {
    imgsrc: "assets/images/AboutUs/clientlogo/aic-steel.png"
  },
  {
    imgsrc: "assets/images/client/Patton.jpg"
  },
  {
    imgsrc: "assets/images/client/Linc.jpg"
  },
  {
    imgsrc: "assets/images/client/indorama3.jpg"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/ampl.jpg"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/utkarsh.png"
  }
];
const ClientSlider4 = [
  {
    imgsrc: "assets/images/AboutUs/clientlogo/orient.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/amitec.png"
  },
  {
    imgsrc: "assets/images/client/Anmol.jpg"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/cont-steel-corp.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/jamna-auto-industries.png"
  },
  {
    imgsrc: "assets/images/AboutUs/clientlogo/tirupati-structurals.jpg"
  }
];

class ClientSlider extends React.Component {
  render() {
    return (
      <Col lg="2" sm="4" xs="6">
        <div className="testi-content">
          <div className="testi-box mt-4">
            <img
              src={`${process.env.IMGIX_URL}/${this.props.imgsrc}?auto=format&auto=compress&h=1000`}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </Col>
    );
  }
}
class ClientSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true
    };
  }
  onSelect = (active, direction) => {
    console.log(`active=${active} && direction=${direction}`);
  };
  visiableOnSelect = active => {
    console.log(`visiable onSelect active=${active}`);
  };
  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };
  _changeIcon = () => {
    let { leftIcon, rightIcon } = this.state;
    leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
    rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
    this.setState({ leftIcon, rightIcon });
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-clients" id="client">
          <div className="bg-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-heading mb-5">
                  <h3 className="text-white mb-1 font-weight-light text-uppercase">
                    Our Clients
                  </h3>
                  <div className="title-border-color position-relative"></div>
                </div>
              </div>
            </div>

            <Row>
              <Col span={12} style={{ marginTop: 20, marginBottom: 50 }}>
                <RBCarousel
                  version={4}
                  autoplay={this.state.autoplay}
                  pauseOnVisibility={true}
                  onSelect={this.visiableOnSelect}
                  slideshowSpeed={2000}
                >
                  {/* First Slider */}
                  <div className="item">
                    <Row>
                      {CliendSlider1.map((client, index) => {
                        return (
                          <ClientSlider key={index} imgsrc={client.imgsrc} />
                        );
                      })}
                    </Row>
                  </div>
                  {/* Second Slider */}
                  <div className="item">
                    <Row>
                      {CliendSlider2.map((client, index) => {
                        return (
                          <ClientSlider key={index} imgsrc={client.imgsrc} />
                        );
                      })}
                    </Row>
                  </div>
                  {/* Third Slider */}
                  <div className="item">
                    <Row>
                      {CliendSlider3.map((client, index) => {
                        return (
                          <ClientSlider key={index} imgsrc={client.imgsrc} />
                        );
                      })}
                    </Row>
                  </div>
                  {/* Forth Slider */}
                  <div className="item">
                    <Row>
                      {ClientSlider4.map((client, index) => {
                        return (
                          <ClientSlider key={index} imgsrc={client.imgsrc} />
                        );
                      })}
                    </Row>
                  </div>
                </RBCarousel>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default ClientSection;
