import React from "react";
import { Link } from "react-router-dom";

import ScrollspyNav from "./scrollSpy";
import ReactGA from "react-ga";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Tab: "" };
  }

  /**
   * Sets active tab
   */
  setActiveTab = (tab, e) => {
    this.setState({ Tab: tab });
  };

  CheckAnalytics(category_name, action_value) {
    ReactGA.event({
      category: category_name,
      action: action_value
    });
  }

  render() {
    return (
      <React.Fragment>
        <nav
          id="main_navbar"
          className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark"
        >
          <div className="container">
            <Link className="navbar-brand logo" to="">
              <img
                src={`${process.env.IMGIX_URL}/assets/images/nowpurchase-logo.png?auto=format&auto=compress&w=140`}
                alt=""
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              {window.location.pathname === "/" && (
                <ScrollspyNav
                  scrollTargetIds={[
                    "home",
                    "products",
                    "resources",
                    "client",
                    "news",
                    "contact"
                  ]}
                  activeNavClass="active"
                  scrollDuration="800"
                  headerBackground="true"
                  className="ml-auto"
                >
                  <ul
                    className="navbar-nav ml-auto navbar-center"
                    id="mySidenav"
                  >
                    <li
                      className="nav-item active"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_HOME_CLICK",
                          "Home Button click from Landing Page"
                        )
                      }
                    >
                      <a href="#home" className="nav-link">
                        Home
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_PRODUCTS_CLICK",
                          "Products Button click from Landing Page"
                        )
                      }
                    >
                      <a href="#products" className="nav-link">
                        Products
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_AWARENESS_RESOUSES_CLICK",
                          "Awareness Resourses click from Landing Page"
                        )
                      }
                    >
                      <a href="#resources" className="nav-link">
                        Awareness Resourses
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_CLIENT_CLICK",
                          "Clients click from Landing Page"
                        )
                      }
                    >
                      <a href="#client" className="nav-link">
                        Clients
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_NEWS_CLICK",
                          "Clicked on News from Landing Page"
                        )
                      }
                    >
                      <a href="#news" className="nav-link">
                        News
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_CONTACT_CLICK",
                          `Clicked on COntact from Landing Page.`
                        )
                      }
                    >
                      <a href="#contact" className="nav-link">
                        Contact
                      </a>
                    </li>
                  </ul>
                </ScrollspyNav>
              )}
              {window.location.pathname !== "/" && (
                <div className="ml-auto">
                  <ul className="navbar-nav ml-auto navbar-center">
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_HOME_CLICK",
                          `Home Button click ${window.location.pathname}`
                        )
                      }
                    >
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_PRODUCTS_CLICK",
                          `Product Button click ${window.location.pathname}`
                        )
                      }
                    >
                      <a
                        href="https://nowpurchase.com/storeproducts/coronavirusessentials"
                        className="nav-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Products
                      </a>
                    </li>
                    <li
                      className={
                        "nav-item " +
                        (window.location.pathname === "/doc" ? "active" : "")
                      }
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_AWARENESS_RESOURSES_CLICK",
                          `Awareness Resourses Button click ${window.location.pathname}`
                        )
                      }
                    >
                      <Link to="/doc" className="nav-link">
                        Awareness Resources
                      </Link>
                    </li>
                    <li
                      className={"nav-item "}
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_CLIENT_CLICK",
                          `Client Button click ${window.location.pathname}`
                        )
                      }
                    >
                      <Link to="/#client" className="nav-link">
                        Clients
                      </Link>
                    </li>
                    <li
                      className={
                        "nav-item " +
                        (window.location.pathname === "/feed" ? "active" : "")
                      }
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_NEWS_CLICK",
                          `News Button click ${window.location.pathname}`
                        )
                      }
                    >
                      <Link to="/feed" className="nav-link">
                        News
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        this.CheckAnalytics(
                          "NAVBAR_CONTACT_CLICK",
                          `Contact Button click ${window.location.pathname}`
                        )
                      }
                    >
                      <a href="#contact" className="nav-link">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
