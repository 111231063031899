import React from "react";

export default function ImagePlaceHolder() {
  return (
    <div style={{ position: "relative", paddingTop: "50%" }}>
      <div
        className="flexCenter"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        }}
      >
        <img src="https://img1.nowpurchase.com/assets/images/glogoImage.png" />
      </div>
    </div>
  );
}
