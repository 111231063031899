import React, { useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { cFetch } from "../api/apiUtils";

function ContactSection() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleSubmit = data => {
    setSubmitSuccess(false);
    setSubmitError("");
    cFetch(`${process.env.API_URL}/api/contact_us`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({
        ...data
      })
    })
      .then(() => setSubmitSuccess(true))
      .catch(err => {
        setSubmitSuccess(true);
        throw { err, data, msg: "Contact us form API failed" };
      });
  };

  return (
    <React.Fragment>
      <section className="section bg-light" id="contact">
        <div className="container">
          <div className="row">
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  Get in touch
                </h3>
                <div className="title-border-simple position-relative"></div>
              </div>
            </Col>
          </div>

          <Row>
            <Col lg="12">
              <div className="contact-box p-5">
                <Row>
                  <Col lg="8" md="6">
                    <div className="custom-form p-3">
                      {submitSuccess && (
                        <Alert color="success">
                          Our executive will contact you shortly
                        </Alert>
                      )}
                      {submitError !== "" && (
                        <Alert color="danger">{submitError}</Alert>
                      )}
                      <AvForm
                        autoComplete={"off"}
                        id="contact-form"
                        onValidSubmit={(event, data) => handleSubmit(data)}
                        onInvalidSubmit={(event, errors, values) => {
                          console.log(errors, values);
                          setSubmitError("Please check all inputs.");
                        }}
                      >
                        <Row>
                          <Col lg="6">
                            <div className="form-group app-label">
                              <AvField
                                name="customer_name"
                                type="text"
                                validate={{ required: { value: true } }}
                                placeholder="Name"
                                errorMessage="Invalid Name"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="form-group app-label">
                              <AvField
                                name="customer_phone_no"
                                type="text"
                                validate={{
                                  required: { value: true },
                                  pattern: { value: "\\+?1?\\d{9,15}" }
                                }}
                                placeholder="Phone no"
                                errorMessage="Invalid Phone no"
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="form-group app-label">
                              <AvField
                                name="customer_email"
                                type="text"
                                errorMessage="Invalid Email"
                                validate={{
                                  required: { value: true },
                                  email: { value: true }
                                }}
                                placeholder="Email"
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="form-group app-label">
                              <AvField
                                name="subject"
                                type="text"
                                validate={{ required: { value: true } }}
                                placeholder="Subject"
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="form-group app-label">
                              <AvField
                                name="message"
                                type="textarea"
                                validate={{ required: { value: false } }}
                                rows="5"
                                placeholder="Message"
                              />
                            </div>
                          </Col>
                          <Row>
                            <Col sm="12">
                              &nbsp; &nbsp;{" "}
                              <input
                                type="submit"
                                className="submitBnt btn btn-custom"
                                value="Send Message"
                              />
                            </Col>
                          </Row>
                        </Row>
                      </AvForm>
                    </div>
                  </Col>

                  <div className="col-lg-4 col-md-6">
                    <div className="contact-cantent p-3">
                      <div className="contact-details">
                        <div className="float-left contact-icon mr-3 mt-2">
                          <i className="mdi mdi-headphones text-muted h5"></i>
                        </div>
                        <div className="app-contact-desc text-muted pt-1">
                          <p className="mb-0 info-title f-13">Call :</p>
                          <p className="mb-0 f-13">+91-8240794517</p>
                        </div>
                      </div>

                      <div className="contact-details mt-2">
                        <div className="float-left contact-icon mr-3 mt-2">
                          <i className="mdi mdi-email-outline text-muted h5"></i>
                        </div>
                        <div className="app-contact-desc text-muted pt-1">
                          <p className="mb-0 info-title f-13">Email :</p>
                          <p className="mb-0 f-13">
                            <a
                              href="mailto:care@nowpurchase.com"
                              className="text-muted"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              covid@nowpurchase.com
                            </a>
                          </p>
                        </div>
                      </div>

                      <div className="contact-details mt-2">
                        <div className="float-left contact-icon mr-3 mt-2">
                          <i className="mdi mdi-map-marker text-muted h5"></i>
                        </div>
                        <div className="app-contact-desc text-muted pt-1">
                          <p className="mb-0 info-title f-13">Location :</p>
                          <p className="mb-0 f-13">
                            <Link to="" className="text-muted">
                              Suite 31, 3rd floor, Ganga Jamuna Building, 28/1
                              Shakespeare Sarani, Kolkata,West Bengal-700017
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div className="follow mt-4">
                        <h4 className="text-dark mb-3">Follow</h4>
                        <ul className="follow-icon list-inline mt-32 mb-0">
                          <li className="list-inline-item f-15">
                            <a
                              href="https://www.facebook.com/NowPurchase"
                              className="social-icon text-muted"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <i className="mdi mdi-facebook"></i>
                            </a>
                          </li>
                          &nbsp;
                          <li className="list-inline-item f-15">
                            <a
                              href="https://twitter.com/NowPurchase"
                              className="social-icon text-muted"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <i className="mdi mdi-twitter"></i>
                            </a>
                          </li>
                          &nbsp;
                          <li className="list-inline-item f-15">
                            <a
                              href="https://www.linkedin.com/company/nowpurchase"
                              className="social-icon text-muted"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <i className="mdi mdi-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ContactSection;
