import parse from "date-fns/parse"; // https://date-fns.org/v2.9.0/docs/parse
import format from "date-fns/format";
//source https://github.com/faisalman/ua-parser-js
var UAParser = require("ua-parser-js/dist/ua-parser.min");
var UA = new UAParser();
var device = UA.getDevice(); //returns {vendor: "Samsung", model: "SM-G900P", type: "mobile"}

/**
 *
 * @param {string} iso8601_string ISO8601 date to be converted
 * @param {string} formatString format ISO8601 date as per provided string
 * source firehawk ka OrderClap, and modified by me.
 */
export function to_readable_date(iso8601_string, formatString) {
  if (iso8601_string) {
    return format(parse(iso8601_string), [
      formatString ? formatString : "DD-MM-YYYY hh:mm:ss"
    ]);
  } else {
    return null;
  }
}

/**
 * check user agent is mobile or not.
 * @returns {bool} true in case of mobile else false
 */
export function isMobile() {
  return device && device.type === "mobile";
}
