export async function handleResponse(response) {
  // mostly a delete or a create that does not return a json
  if (response.status === 204) return {};
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

export function cFetch(baseUrl, headers = {}) {
  return fetch(baseUrl, headers)
    .then(handleResponse)
    .catch(handleError);
}
