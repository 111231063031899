import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

class AwarenessImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageList: [
        {
          thumbnail: "Hand_Wash_English.png",
          title: "Hand Wash",
          content: "English Version"
        },
        {
          thumbnail: "Social_Distancing_Hindi.png",
          title: "Social Distancing",
          content: "Hindi Version"
        },
        {
          thumbnail: "Surgical_Mask_Bengali.png",
          title: "Surgical Mask",
          content: "Bengali Version"
        },
        {
          thumbnail: "Ettiqutes_English.png",
          title: "General Etiqutte",
          content: "English Version"
        }
      ]
    };
  }

  CheckAnalytics(category_name, action_value) {
    ReactGA.event({
      category: category_name,
      action: action_value
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-features" style={{ padding: "40px 0" }}>
          <div className="container">
            <Row className="align-items-center" style={{ paddingBottom: 30 }}>
              <Col lg="12">
                <div className="features-content">
                  {/* <div className="features-icon">
                    <i className="pe-7s-science text-primary"></i>
                  </div> */}
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Awareness Posters
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              {this.state.ImageList.map((image, index) => {
                return (
                  <Col lg="3" key={index}>
                    <Link to="/doc">
                      <div className="text-center">
                        <div className="mb-4" style={{ position: "relative" }}>
                          <img
                            src={`${process.env.IMGIX_URL}/safety/${image.thumbnail}?auto=format&auto=compress&h=1500`}
                            onClick={() =>
                              this.CheckAnalytics(
                                "IMAGE_CLICK",
                                `Clicked on ${image.title} image.`
                              )
                            }
                          />
                        </div>
                        <h4
                          className="font-weight-light"
                          onClick={() =>
                            this.CheckAnalytics(
                              "IMAGE_TITLE_CLICK",
                              `Clicked on ${image.title} image title.`
                            )
                          }
                        >
                          <div to="#" className="text-dark">
                            {image.title}
                          </div>
                        </h4>
                        <p
                          className="text-muted f-14"
                          onClick={() =>
                            this.CheckAnalytics(
                              "IMAGE_CONTENT_CLICK",
                              `Clicked on ${image.title} image Content.`
                            )
                          }
                        >
                          {image.content}
                        </p>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
            <p
              className="mb-0 text-uppercase f-13"
              style={{ textAlign: "right" }}
            >
              <Link
                to="/doc"
                className="text-primary"
                onClick={() =>
                  this.CheckAnalytics(
                    "AWARENESS_IMAGE_VIEW_ALL_CLICK",
                    "Clicked on view all button in awareness image section."
                  )
                }
              >
                view all
                <i className="mdi mdi-arrow-right ml-2"></i>
              </Link>
            </p>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AwarenessImage;
