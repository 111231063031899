import React from "react";

class FooterAlt extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer-alt bg-dark pt-3 pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <p className="copyright text-white f-14 font-weight-light mb-0">
                  <a
                    rel="license"
                    href="http://creativecommons.org/licenses/by-nc/4.0/"
                  >
                    <img
                      alt="Creative Commons License"
                      style={{ borderWidth: 0 }}
                      src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
                    />
                  </a>
                  <br />
                  This work is licensed under a{" "}
                  <a
                    rel="license"
                    href="http://creativecommons.org/licenses/by-nc/4.0/"
                    style={{ color: "#fff" }}
                  >
                    Creative Commons Attribution-NonCommercial 4.0 International
                    License
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default FooterAlt;
