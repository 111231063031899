import React from "react";
import Home4 from "./components/Home4";
import AwarenessResourse from "./components/AwarenessResourse";
import ClientSection from "./components/ClientSection_1";
import NewsSection from "./components/NewsFeed";
import Contribute from "./components/ContributeSection";
import Product from "./components/ProductSection";

class Index4 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* HomeSection Menu */}
        <Home4 />

        {/* HomeSection Menu */}
        <Contribute />

        <Product />
        {/* ProductSection Menu */}
        {/* <ProductSection /> */}

        {/* FeatureSection Menu */}
        <AwarenessResourse />

        {/* ClientSection Menu */}
        <ClientSection />

        {/* BlogSection Menu */}
        <NewsSection />
      </React.Fragment>
    );
  }
}

export default Index4;
