import React from "react";
import { Spinner } from "reactstrap";

function SpinnerWrapper() {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spinner color="success" style={{ width: "2rem", height: "2rem" }} />
    </div>
  );
}
export default SpinnerWrapper;
