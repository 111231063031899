import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AwarenessVideo from "./AwarenessVideo";
import AwarenessImage from "./AwarenessImage";
import ReactGA from "react-ga";

class AwarenessResourse extends React.Component {
  CheckAnalytics(category_name, action_value) {
    ReactGA.event({
      category: category_name,
      action: action_value
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-features"
          id="resources"
          style={{ paddingBottom: 40 }}
        >
          <div className="container">
            <Row>
              <Col className="col-lg-12">
                <div className="title-heading mb-5">
                  <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                    Awareness Resources
                  </h3>
                  <div className="title-border-simple position-relative"></div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center" style={{ padding: "25px 0" }}>
              <Col lg="8">
                <div className="features-content">
                  {/* <div className="features-icon">
                    <i className="pe-7s-science text-primary"></i>
                  </div> */}
                  <h4 className="font-weight-normal text-dark mb-3">
                    SOP for administration
                  </h4>
                  <div className="features-icon  mb-3">
                    <i className="pe-7s-shuffle text-primary"></i>
                  </div>
                  <p className="text-muted f-14">
                    This document is based on studies carried out by AIIMS, WHO
                    & other apex bodies. Our research team has created this
                    concise document as a ready reckoner for the manufacturing
                    sector.
                  </p>
                  <p className="text-muted f-14">
                    It is important that institutions set up the required
                    processes in place to minimize the risk of the spread of
                    COVID-19 infectious disease and further shutting down of
                    operations.
                  </p>
                  <p className="mb-0 text-uppercase f-13">
                    <Link
                      to="/doc"
                      className="text-primary"
                      onClick={() =>
                        this.CheckAnalytics(
                          "SOP_ADMINISTRATION_LEARN_MORE_CLICK",
                          "Clicked on learn more button"
                        )
                      }
                    >
                      learn more
                      <i className="mdi mdi-arrow-right ml-2"></i>
                    </Link>
                  </p>
                </div>
              </Col>
              <Col lg="4">
                <div className="features-img mt-32">
                  <Link
                    to="/doc"
                    onClick={() =>
                      this.CheckAnalytics(
                        "SOP_DOCUMENT_CLICK",
                        "Clicked on SOP document"
                      )
                    }
                  >
                    <img
                      src={`${process.env.IMGIX_URL}/safety/COVID_DOC_FIRST_PAGE.jpg?auto=format&auto=compress&h=1000&border=3,297cbe`}
                      alt=""
                      className="img-fluid ml-auto d-block mb-img"
                      style={{ maxHeight: 350 }}
                    />
                    <span className="wb-sop-right mb-sop-right overlay"></span>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section
          className="section bg-features  bg-light"
          id="resources"
          style={{ paddingBottom: 40 }}
        >
          <div className="container">
            <Row className="align-items-center mob-align" style={{ paddingBottom: "25px" }}>
              <Col lg="6">
                <div className="features-img mt-32">
                  <Link
                    to="/doc"
                    onClick={() =>
                      this.CheckAnalytics(
                        "DAILY_CHECKLIST_CLICK",
                        "Clicked on Daily Checklist"
                      )
                    }
                  >
                    <img
                      src={`${process.env.IMGIX_URL}/safety/Checklist_1.png?auto=format&auto=compress&h=1500`}
                      alt=""
                      className="img-fluid mr-auto d-block mb-img"
                      style={{ maxHeight: 300 }}
                    />
                    <span className="wb-check-right mb-check-right overlay"></span>
                  </Link>
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  {/* <div className="features-icon">
                    <i className="pe-7s-science text-primary"></i>
                  </div> */}
                  <h4 className="font-weight-normal text-dark mb-3">
                    Daily Checklist
                  </h4>
                  <div className="features-icon  mb-3">
                    <i className="pe-7s-display1 text-primary"></i>
                  </div>
                  <p className="text-muted f-14">
                    A simple daily checklist to make sure the entire workplace
                    is adhering to hygiene and safety norms as cited by the
                    Ministry of Home Affairs (MHA)
                  </p>
                  <p className="mb-0 text-uppercase f-13">
                    <Link
                      to="/doc"
                      className="text-primary"
                      onClick={() =>
                        this.CheckAnalytics(
                          "DAILY_CHECKLIST_LEARN_MORE_CLICK",
                          "Clicked on learn more button"
                        )
                      }
                    >
                      learn more
                      <i className="mdi mdi-arrow-right ml-2"></i>
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <AwarenessImage />
        <AwarenessVideo />
      </React.Fragment>
    );
  }
}
export default AwarenessResourse;
