import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt,
  faIdBadge,
  faBuilding,
  faAt
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
const COVID_PPE_CATALOGUE = "COVID_PPE_CATALOGUE";
const COVID_SUPPLIER_REQUEST = "COVID_SUPPLIER_REQUEST";
function ContributeSection() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const cookieOptions = { path: "/", maxAge: 604800 };
  const [cookies, setCookie] = useCookies([
    "customer_name",
    "customer_whatsapp_no",
    "company_name",
    "customer_email"
  ]);

  const [modalInputs, setModalInputs] = useState({
    customer_name: cookies.customer_name || "",
    customer_whatsapp_no: cookies.customer_whatsapp_no || "",
    company_name: cookies.company_name || "",
    customer_email: cookies.customer_email || "",
    data_source: ""
  });
  const [activeModalLinkDownload, setActiveModalLinkDownload] = useState({});
  const handleModalInputs = (e, indexName) => {
    setModalInputs({ ...modalInputs, [indexName]: e.target.value });
  };

  /**
   * this function open desired file requested by user and also store relevant data for analytics on backend and GS
   * @param {String} anchorLink Url of requested data to be opened
   * @param {String} data_source tag for analytics and data source
   */
  function downloadPDF(anchorLink, data_source) {
    if (anchorLink) {
      window.open(anchorLink, "_self");
    } else {
      toast.success("Our representative will contact you shortly");
    }
    if (modal) toggle();
    // toast.success("")
    fetch(`${process.env.API_URL}/api/customer_data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({
        ...modalInputs,
        data_source
      })
    });
    setCookie("customer_name", modalInputs.customer_name, cookieOptions);
    setCookie(
      "customer_whatsapp_no",
      modalInputs.customer_whatsapp_no,
      cookieOptions
    );
    setCookie("company_name", modalInputs.company_name, cookieOptions);
    setCookie("customer_email", modalInputs.customer_email, cookieOptions);
    ReactGA.event({
      category: "MODAL_DOWNLOAD",
      action: `${data_source} downloaded`
    });
  }
  /**
   * this model check if user already submitted required data for downloading files, if not then open modal else triggers downloading
   * @param {String} data_source tag for analytics and data source
   */
  const downloadModal = data_source => {
    let anchorLink = null;
    if (data_source === COVID_PPE_CATALOGUE) {
      anchorLink = "https://cdn.nowpurchase.com/CombatCOVIDWithoutPrice.pdf";
    }

    if (modalInputs.customer_name) {
      downloadPDF(anchorLink, data_source);
    } else {
      toggle();
      setActiveModalLinkDownload({ anchorLink, data_source });
      ReactGA.modalview(data_source);
    }
  };
  function CheckAnalytics(category_name, action_value) {
    ReactGA.event({
      category: category_name,
      action: action_value
    });
  }

  function modalSubmit(e) {
    e.preventDefault();
    downloadPDF(
      activeModalLinkDownload.anchorLink,
      activeModalLinkDownload.data_source
    );
  }

  return (
    <React.Fragment>
      <section className="section">
        <div className="container">
          <Row>
            <Col lg="4">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  CONTRIBUTE
                </h3>
                <div className="title-border-simple position-relative"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <div
                className="about-box about-light text-center p-3"
                onClick={() => {
                  downloadModal(COVID_PPE_CATALOGUE);
                }}
              >
                <div className="about-icon mb-4">
                  <i className="mdi mdi-lightbulb"></i>
                </div>
                <h4 className="font-weight-light">
                  <span href="#" className="text-dark">
                    Share PPE Catalogue
                  </span>
                </h4>
                <p className="text-muted f-14">
                  Share a range of products we have identified to keep your
                  workplace safe during this time.
                </p>
              </div>
            </Col>
            <Col lg="4">
              <div
                className="about-box about-light text-center p-3"
                onClick={() => {
                  downloadModal(COVID_SUPPLIER_REQUEST);
                }}
              >
                <div className="about-icon mb-4">
                  <i className="mdi mdi-projector-screen"></i>
                </div>
                <h4 className="font-weight-light">
                  <Link to="#" className="text-dark">
                    Supplier Sign Up
                  </Link>
                </h4>
                <p className="text-muted f-14">
                  Sign up with us to become a verified supplier of PPE or MRO
                  items in our platform to help contain the supply chain
                  disruption.
                </p>
              </div>
            </Col>
            <Col lg="4">
              <div className="about-box about-light text-center p-3">
                <a
                  href="https://indiafightscorona.giveindia.org/support-families/"
                  className="text-dark"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    CheckAnalytics(
                      "CONTRIBUTE_DONATE_CLICK",
                      "Clicked on donate button"
                    )
                  }
                >
                  <div className="about-icon mb-4">
                    <i className="mdi mdi-nature"></i>
                  </div>
                </a>
                <h4 className="font-weight-light">
                  <a
                    href="https://indiafightscorona.giveindia.org/support-families/"
                    className="text-dark"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Donate
                  </a>
                </h4>
                <a href="https://indiafightscorona.giveindia.org/support-families/">
                  <p
                    className="text-muted f-14"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support COVID-19 Hit Families who depend on a daily wage
                    earning family member.
                  </p>
                </a>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="documentDownloadModal"
          >
            <form onSubmit={modalSubmit}>
              <ModalHeader toggle={toggle}>
                {activeModalLinkDownload.data_source === COVID_PPE_CATALOGUE
                  ? "Download our product catalog"
                  : "Please share your details"}
              </ModalHeader>
              <ModalBody>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faIdBadge} color="#4682b4" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter you name"
                    required
                    value={modalInputs.customer_name}
                    onChange={e => handleModalInputs(e, "customer_name")}
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faWhatsapp} color="#4682b4" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your whatsapp no"
                    pattern="\+?1?\d{9,15}"
                    required
                    value={modalInputs.customer_whatsapp_no}
                    onChange={e => handleModalInputs(e, "customer_whatsapp_no")}
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faBuilding} color="#4682b4" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter you company name (optional)"
                    value={modalInputs.company_name}
                    onChange={e => handleModalInputs(e, "company_name")}
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faAt} color="#4682b4" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter you email (optional)"
                    value={modalInputs.customer_email}
                    onChange={e => handleModalInputs(e, "customer_email")}
                  />
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary">
                  {activeModalLinkDownload.data_source === COVID_PPE_CATALOGUE
                    ? "Download"
                    : "submit"}
                </Button>{" "}
                <Button type="button" color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ContributeSection;
