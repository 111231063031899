import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App";
import { CookiesProvider } from "react-cookie";
import Rollbar from "rollbar";
// Source: https://dev.to/guimg/dynamic-document-head-with-react-helmet-28o6

const rollbar = new Rollbar({
  accessToken: "387ab5b11ed2491491297fb853cf73ad",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment:
      process.env.NODE_ENV === "production"
        ? "CovidProduction"
        : "CovidDevelopment"
  },
  enabled: process.env.NODE_ENV === "production"
});

render(
  <CookiesProvider>
    <Router>
      <App />
    </Router>
  </CookiesProvider>,
  document.getElementById("app")
);
