import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer-bg">
          <div className="container">
            <Row>
              <Col lg="4">
                <div className="mb-5">
                  <p className="text-uppercase text-dark footer-title mb-4">
                    About Us
                  </p>
                  <p className="text-muted f-14">
                    NowPurchase helps you stay safe with one stop solution for
                    both PPEs and MRO products.
                  </p>
                </div>
              </Col>

              <Col lg="8">
                <Row>
                  <Col lg="4">
                    <div className="mb-5">
                      <p className="text-uppercase text-dark footer-title mb-4">
                        company
                      </p>
                      <ul className="list-unstyled footer-sub-menu">
                        <li className="f-14">
                          <a
                            href={`${process.env.NP_ECOM_URL}/aboutus?utm_source=covid_np&utm_medium=footer`}
                            className="text-muted"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            About our journey
                          </a>
                        </li>
                        <li className="f-14">
                          <a
                            href={`${process.env.NP_ECOM_URL}?utm_source=covid_np&utm_medium=footer`}
                            className="text-muted"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Foundry Focus
                          </a>
                        </li>
                        <li className="f-14">
                          <a
                            href={`${process.env.NP_ECOM_URL}/store?utm_source=covid_np&utm_medium=footer`}
                            className="text-muted"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ecommerce Store
                          </a>
                        </li>
                        <li className="f-14">
                          <a
                            href="https://yourstory.com/smbstory/b2b-ecommerce-nowpurchase-west-bengal"
                            className="text-muted"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Latest Press
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-5">
                      <p className="text-uppercase text-dark footer-title mb-4">
                        Legal
                      </p>
                      <ul className="list-unstyled footer-sub-menu">
                        <li className="f-14">
                          <a
                            href={`${process.env.NP_ECOM_URL}/policy/terms?utm_source=covid_np&utm_medium=footer`}
                            className="text-muted"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        <li className="f-14">
                          <a
                            href={`${process.env.NP_ECOM_URL}/policy/privacy?utm_source=covid_np&utm_medium=footer`}
                            className="text-muted"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-5">
                      <p className="text-uppercase text-dark footer-title mb-4">
                        Contact Us
                      </p>
                      <ul className="list-unstyled footer-sub-menu">
                        <li className="f-14">
                          <div className="contact-details">
                            <div className="float-left contact-icon mr-3 mt-2">
                              <i className="mdi mdi-headphones text-muted h5"></i>
                            </div>
                            <div className="app-contact-desc text-muted pt-1">
                              <p className="mb-0 info-title f-13">Call :</p>
                              <p className="mb-0 f-13">+91-8240794517</p>
                            </div>
                          </div>
                        </li>
                        <li className="f-14">
                          <div className="contact-details mt-2">
                            <div className="float-left contact-icon mr-3 mt-2">
                              <i className="mdi mdi-email-outline text-muted h5"></i>
                            </div>
                            <div className="app-contact-desc text-muted pt-1">
                              <p className="mb-0 info-title f-13">Email :</p>
                              <p className="mb-0 f-13">
                                <a
                                  href="mailto:care@nowpurchase.com"
                                  className="text-muted"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  covid@nowpurchase.com
                                </a>
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="f-14">
                          <div className="contact-details mt-2">
                            <div className="float-left contact-icon mr-3 mt-2">
                              <i className="mdi mdi-map-marker text-muted h5"></i>
                            </div>
                            <div className="app-contact-desc text-muted pt-1">
                              <p className="mb-0 info-title f-13">Location :</p>
                              <p className="mb-0 f-13">
                                <Link to="" className="text-muted">
                                  Suite 31, 3rd floor, Ganga Jamuna Building,
                                  28/1 Shakespeare Sarani, Kolkata,West
                                  Bengal-700017
                                </Link>
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Footer;
