import React, { useState } from "react";
import ScrollspyNav from "./scrollSpy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt,
  faIdBadge,
  faBuilding,
  faAt
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
const REQUEST_FOR_CALL = "REQUEST_FOR_CALL";

function Home4() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const cookieOptions = { path: "/", maxAge: 604800 };
  const [cookies, setCookie] = useCookies([
    "customer_name",
    "customer_whatsapp_no",
    "company_name",
    "customer_email"
  ]);

  const [modalInputs, setModalInputs] = useState({
    customer_name: cookies.customer_name || "",
    customer_whatsapp_no: cookies.customer_whatsapp_no || "",
    company_name: cookies.company_name || "",
    customer_email: cookies.customer_email || "",
    data_source: ""
  });
  const [activeModalLinkDownload, setActiveModalLinkDownload] = useState({});
  const handleModalInputs = (e, indexName) => {
    setModalInputs({ ...modalInputs, [indexName]: e.target.value });
  };

  /**
   * this function open desired file requested by user and also store relevant data for analytics on backend and GS
   * @param {String} anchorLink Url of requested data to be opened
   * @param {String} data_source tag for analytics and data source
   */
  function downloadPDF(anchorLink, data_source) {
    if (anchorLink) {
      window.open(anchorLink, "_self");
    } else {
      toast.success("Our representative will contact you shortly");
    }
    if (modal) toggle();
    // toast.success("")
    fetch(`${process.env.API_URL}/api/customer_data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({
        ...modalInputs,
        data_source
      })
    });
    setCookie("customer_name", modalInputs.customer_name, cookieOptions);
    setCookie(
      "customer_whatsapp_no",
      modalInputs.customer_whatsapp_no,
      cookieOptions
    );
    setCookie("company_name", modalInputs.company_name, cookieOptions);
    setCookie("customer_email", modalInputs.customer_email, cookieOptions);
    ReactGA.event({
      category: "MODAL_DOWNLOAD",
      action: `${data_source} downloaded`
    });
  }
  /**
   * this model check if user already submitted required data for downloading files, if not then open modal else triggers downloading
   * @param {String} data_source tag for analytics and data source
   */
  const downloadModal = data_source => {
    let anchorLink = null;
    if (modalInputs.customer_name) {
      downloadPDF(anchorLink, data_source);
    } else {
      toggle();
      setActiveModalLinkDownload({ anchorLink, data_source });
      ReactGA.modalview(data_source);
    }
  };

  function modalSubmit(e) {
    e.preventDefault();
    downloadPDF(
      activeModalLinkDownload.anchorLink,
      activeModalLinkDownload.data_source
    );
  }

  return (
    <React.Fragment>
      <section
        className="section bg-about bg-light-about bg-light homesection"
        id="home"
        style={{ paddingBottom: 0 }}
      >
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container" style={{ maxWidth: "90%" }}>
              <Row className="align-items-center">
                <Col className="col-lg-6 position-static">
                  <div className="mt-40 home-4-content">
                    <h1 className="font-weight-normal home-2-title mb-0">
                      NowPurchase prepares you to combat COVID-19
                    </h1>
                    <p
                      className=" mt-4 mb-0 homepara"
                      style={{ color: "#000" }}
                    >
                      As India restarts its economic activities, it is essential
                      that we go to work safely. We, at NowPurchase, have a
                      dedicated COVID-19 Task Force to help businesses adhere to
                      the required SOP by providing them supplies & guidance.
                    </p>
                    <p className="mt-4 mb-0 homepara" style={{ color: "#000" }}>
                      Click below to schedule a call.
                    </p>
                    <div
                      className="subcribe-form"
                      style={{ marginTop: "2rem" }}
                    >
                      {/* <form>
                          <input
                            type="text"
                            className="mdi mdi-send  "
                            placeholder="Enter Email.."
                          />
                          &nbsp; */}
                      <ScrollspyNav
                        scrollTargetIds={[
                          "home",
                          "products",
                          "resources",
                          "client",
                          "news",
                          "contact"
                        ]}
                        activeNavClass="active"
                        scrollDuration="800"
                        headerBackground="true"
                        className="ml-auto"
                      >
                        <a
                          href="#"
                          className="nav-link btn btn-w btn-custom mt-10"
                          style={{ display: "inline-block" }}
                          onClick={() => {
                            downloadModal(REQUEST_FOR_CALL);
                          }}
                        >
                          Request a Call
                        </a>
                      </ScrollspyNav>
                      {/* </form> */}
                    </div>
                  </div>
                </Col>

                <Col lg="6" className="position-static">
                  <div className="mt-40 home-4-img position-relative pl-5">
                    <iframe
                      className="img-fluid mx-auto d-block videoblock"
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/HB6idhJB464?autoplay=1&mute=1"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle} className="documentDownloadModal">
          <form onSubmit={modalSubmit}>
            <ModalHeader toggle={toggle}>Please share your details</ModalHeader>
            <ModalBody>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faIdBadge} color="#4682b4" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Enter you name"
                  required
                  value={modalInputs.customer_name}
                  onChange={e => handleModalInputs(e, "customer_name")}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faWhatsapp} color="#4682b4" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Enter your whatsapp no"
                  pattern="\+?1?\d{9,15}"
                  required
                  value={modalInputs.customer_whatsapp_no}
                  onChange={e => handleModalInputs(e, "customer_whatsapp_no")}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faBuilding} color="#4682b4" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Enter you company name (optional)"
                  value={modalInputs.company_name}
                  onChange={e => handleModalInputs(e, "company_name")}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faAt} color="#4682b4" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Enter you email (optional)"
                  value={modalInputs.customer_email}
                  onChange={e => handleModalInputs(e, "customer_email")}
                />
              </InputGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Request Callback</Button>{" "}
              <Button type="button" color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </section>
    </React.Fragment>
  );
}
export default Home4;
