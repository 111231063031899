import React, { useState, useEffect } from "react";
import "../css/documents.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt,
  faIdBadge,
  faBuilding,
  faAt
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const POSTERS = [
  {
    title: "Hand_Wash_English",
    link: "/posters/Handwash English.pdf",
    thumbnail: "/safety/Hand_Wash_English.png"
  },
  {
    title: "Hand_Wash_Hindi",
    link: "/posters/Handwash Hindi.pdf",
    thumbnail: "/safety/Hand_Wash_Hindi.png"
  },
  {
    title: "Hand_Wash_Bengali",
    link: "/posters/Handwash Bengali.pdf",
    thumbnail: "/safety/Hand_Wash_Bengali.png"
  },
  {
    title: "Social_Distancing_English",
    link: "/posters/Social Distance English.pdf",
    thumbnail: "/safety/Social_Distancing_English.png"
  },
  {
    title: "Social_Distancing_Hindi",
    link: "/posters/Social Distance Hindi.pdf",
    thumbnail: "/safety/Social_Distancing_Hindi.png"
  },
  {
    title: "Social_Distancing_Bengali",
    link: "/posters/Social Distance Bengali.pdf",
    thumbnail: "/safety/Social_Distancing_Bengali.png"
  },
  {
    title: "Surgical_Mask_English",
    link: "/posters/Surgical Mask English.pdf",
    thumbnail: "/safety/Surgical_Mask_English.png"
  },
  {
    title: "Surgical_Mask_Hindi",
    link: "/posters/Surgical Mask Hindi.pdf",
    thumbnail: "/safety/Surgical_Mask_Hindi.png"
  },
  {
    title: "Surgical_Mask_Bengali",
    link: "/posters/Surgical Mask Bengali.pdf",
    thumbnail: "/safety/Surgical_Mask_Bengali.png"
  },
  {
    title: "General Etiqutte English",
    link: "/posters/General-ettiqutes_English.pdf",
    thumbnail: "/safety/Ettiqutes_English.png"
  }
];
const COVID_DOCUMENT_DOWNLOAD = "COVID_DOCUMENT_DOWNLOAD"; //PDF link constant

const DAILY_CHECKLIST_DOWNLOAD = "DAILY_CHECKLIST_DOWNLOAD"; //Checklist PDF link constant

function DocAndPosters() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const cookieOptions = { path: "/", maxAge: 604800 }; // Max age 1 week 24*60*60*7

  const [cookies, setCookie] = useCookies([
    "customer_name",
    "customer_whatsapp_no",
    "company_name",
    "customer_email"
  ]);

  const [activeModalLinkDownload, setActiveModalLinkDownload] = useState({});

  const [modalInputs, setModalInputs] = useState({
    customer_name: cookies.customer_name || "",
    customer_whatsapp_no: cookies.customer_whatsapp_no || "",
    company_name: cookies.company_name || "",
    customer_email: cookies.customer_email || "",
    data_source: ""
  });

  const handleModalInputs = (e, indexName) => {
    setModalInputs({ ...modalInputs, [indexName]: e.target.value });
  };

  // for google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document.getElementById("main_navbar").classList.remove("navbar-light");
  }, []);

  function modalSubmit(e) {
    e.preventDefault();
    downloadPDF(
      activeModalLinkDownload.anchorLink,
      activeModalLinkDownload.data_source
    );
  }

  /**
   * this function open desired file requested by user and also store relevant data for analytics on backend and GS
   * @param {String} anchorLink Url of requested data to be opened
   * @param {String} data_source tag for analytics and data source
   */
  function downloadPDF(anchorLink, data_source) {
    window.open(anchorLink, "_self");
    if (modal) toggle();
    // toast.success("")
    fetch(`${process.env.API_URL}/api/customer_data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({
        ...modalInputs,
        data_source
      })
    });
    setCookie("customer_name", modalInputs.customer_name, cookieOptions);
    setCookie(
      "customer_whatsapp_no",
      modalInputs.customer_whatsapp_no,
      cookieOptions
    );
    setCookie("company_name", modalInputs.company_name, cookieOptions);
    setCookie("customer_email", modalInputs.customer_email, cookieOptions);
    ReactGA.event({
      category: "MODAL_DOWNLOAD",
      action: `${data_source} downloaded`
    });
  }

  /**
   * this model check if user already submitted required data for downloading files, if not then open modal else triggers downloading
   * @param {String} data_source tag for analytics and data source
   */
  const downloadModal = data_source => {
    let anchorLink;
    if (data_source === COVID_DOCUMENT_DOWNLOAD) {
      anchorLink = "https://cdn.nowpurchase.com/COVID19Preparedness.pdf";
    } else if (data_source === DAILY_CHECKLIST_DOWNLOAD) {
      anchorLink = "https://cdn.nowpurchase.com/posters/Checklist.pdf";
    } else {
      anchorLink = POSTERS.filter(ele => ele.title === data_source).map(
        ele => `https://cdn.nowpurchase.com${ele.link}`
      )["0"];
    }

    //alert(pdfDocLink);

    // let anchorLink =
    //   data_source === COVID_DOCUMENT_DOWNLOAD
    //     ? pdfDocLink
    //     : POSTERS.filter(ele => ele.title === data_source).map(
    //         ele => `https://cdn.nowpurchase.com${ele.link}`
    //       )["0"];
    // console.log(anchorLink);

    if (modalInputs.customer_name) {
      downloadPDF(anchorLink, data_source);
    } else {
      toggle();
      setActiveModalLinkDownload({ anchorLink, data_source });
      ReactGA.modalview(data_source);
    }
  };

  return (
    <React.Fragment>
      <section
        className="document_container section"
        style={{ height: "85vh" }}
      >
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <Row>
                <Col className="col-lg-12">
                  <div className="title-heading mb-5">
                    <h3 className="text-dark mb-1 font-weight-light">
                      COVID-19 PREPAREDNESS DOCUMENT FOR THE MANUFACTURING
                      SECTOR
                    </h3>
                    <div className="title-border-simple position-relative"></div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={12} xs={12} className="flexCenter">
                  <div
                    className="downloadPdf text-center features-img mt-32"
                    style={{ position: "relative" }}
                    onClick={() => {
                      downloadModal(COVID_DOCUMENT_DOWNLOAD);
                    }}
                  >
                    <img
                      src={`${process.env.IMGIX_URL}/safety/COVID_DOC_FIRST_PAGE.jpg?auto=format&auto=compress&h=1000&border=3,297cbe`}
                      className="img-fluid mx-auto d-block"
                      style={{ maxHeight: "300" }}
                    />
                    <div className="flexCenter downloadText">
                      <FontAwesomeIcon
                        icon={faCloudDownloadAlt}
                        color="#4682b4"
                      />
                      <div style={{ marginLeft: 10 }}>DOWNLOAD</div>
                    </div>
                  </div>
                </Col>
                <Col md={9} sm={12} xs={12}>
                  <div className="features-content">
                    <h4 className="font-weight-normal text-dark mb-3 mt-4">
                      ABSTRACT
                    </h4>
                    <div>
                      <p>
                        This document is a literature review of the current body
                        of research done on COVID-19 and suggests guidelines
                        that factories can implement so that its processes can
                        function while minimizing the risk of spreading the
                        COVID-19 infectious disease among its workers.
                        Negligence at this level can result in a major number of
                        workers being quarantined/hospitalized that can possibly
                        lead to major disruptions in operations or even complete
                        shutdown of the underlying business. This is in addition
                        to the moral responsibility to make sure that a place of
                        a congregation should not generate new clusters of
                        infected individuals and harm the country’s war against
                        this disease.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <Row>
            <Col className="col-lg-12">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light">
                  PRINTABLE POSTERS
                </h3>
                <div className="title-border-simple position-relative"></div>
              </div>
            </Col>
          </Row>
          <Row>
            {POSTERS.map((ele, index) => (
              <Col key={index} md="3" xs="12" sm="6">
                <div
                  onClick={() => {
                    downloadModal(ele.title);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={`${process.env.IMGIX_URL}${ele.thumbnail}?auto=format&auto=compress&h=1000`}
                    alt={ele.title.replace(/_/g, " ")}
                  />
                  <div style={{ padding: 15 }} className="text-center">
                    {ele.title.replace(/_/g, " ")}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <section style={{ padding: "50px 0" }}>
            <Row>
              <Col className="col-lg-12">
                <div className="title-heading mb-5">
                  <h3 className="text-dark mb-1 font-weight-light">
                    Daily Checklist
                  </h3>
                  <div className="title-border-simple position-relative"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-5">
                <div
                  onClick={() => {
                    downloadModal(DAILY_CHECKLIST_DOWNLOAD);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={`${process.env.IMGIX_URL}/safety/Checklist_1.png?auto=format&auto=compress&h=1500`}
                    alt="Daily Checklist"
                  />
                  <div style={{ padding: 15 }} className="text-center">
                    Daily Checklist
                  </div>
                </div>
              </Col>
            </Row>
          </section>
          <div>
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="documentDownloadModal"
            >
              <form onSubmit={modalSubmit}>
                <ModalHeader toggle={toggle}>
                  Download for free and get updates on WhatsApp
                </ModalHeader>
                <ModalBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faIdBadge} color="#4682b4" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter you name"
                      required
                      value={modalInputs.customer_name}
                      onChange={e => handleModalInputs(e, "customer_name")}
                    />
                  </InputGroup>
                  <br />
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faWhatsapp} color="#4682b4" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter your whatsapp no"
                      pattern="\+?1?\d{9,15}"
                      required
                      value={modalInputs.customer_whatsapp_no}
                      onChange={e =>
                        handleModalInputs(e, "customer_whatsapp_no")
                      }
                    />
                  </InputGroup>
                  <br />
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faBuilding} color="#4682b4" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter you company name (optional)"
                      value={modalInputs.company_name}
                      onChange={e => handleModalInputs(e, "company_name")}
                    />
                  </InputGroup>
                  <br />
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faAt} color="#4682b4" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter you email (optional)"
                      value={modalInputs.customer_email}
                      onChange={e => handleModalInputs(e, "customer_email")}
                    />
                  </InputGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary">Download</Button>{" "}
                  <Button type="button" color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default DocAndPosters;
