import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Feed from "../components/feed";
import Navbar from "./Navbar";
import Preloader from "./Preloader";
import DocAndPosters from "../components/docAndPosters";
import ReactGA from "react-ga";
import HomePage from "../Index4";
import Footer from "./Footer";
import ContactSection from "./ContactSection";
import FooterAlt from "./FooterAlt";

ReactGA.initialize("UA-94032713-6");

function TypeForm() {
  return (
    <div style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
      <iframe
        id="typeform-full"
        width="100%"
        height="100%"
        title="survey"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src="https://nowpurchasebiz.typeform.com/to/FlE8d4"
      ></iframe>
    </div>
  );
}

function App() {
  return (
    <>
      {/* Navigation Menu */}
      <Navbar />
      {/* preloader */}
      <Preloader />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/feed" component={Feed} />
        <Route exact path="/survey" component={TypeForm} />
        <Route exact path="/doc" component={DocAndPosters} />
      </Switch>
      <ToastContainer autoClose={3000} hideProgressBar />
      {/* ContactSection Menu */}
      <ContactSection />
      {/* Footer Menu */}
      <Footer />
      {/* FooterAlt Menu */}
      <FooterAlt />
    </>
  );
}

export default withRouter(App);
